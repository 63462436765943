<template>
  <button
    :disabled="isDisabled"
    :class="[customClass ? customClass : 'btn flex items-center justify-center bg-primary w-full rounded-lg h-14 hover:bg-bg-hover text-white font-medium mb-6 text-sm']"
  >
    <slot name="spin"></slot>
    <slot name="check"></slot>
    <slot name="cancel"></slot>
    <slot name="value"></slot>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    value: String,
    customClass: String,
    isDisabled: Boolean,
  },
  methods: {
    toNext() {
      // this.$emit('next');
    }
  },
};
</script>

<style lang="scss" >
.btn {
  font-family: SohneKraftig;
  &:active,
  &:focus {
    outline: none;
  }
}
</style>